import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          background: "#f0f3ed",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        className='section section-with-space'
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className=''>
                <h1 className='section-title text-center text-danger'>
                  <b>HEAR FROM OUR PARTNERS</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='text-center'>
              <Slider {...settings}>
                {content.map((c) => (
                  <div className='w-75 mx-auto'>
                    <h2
                      className='text-dark text-700 pt-5'
                      dangerouslySetInnerHTML={{ __html: c.testimonial }}
                    />
                    <h1 className='text-dark text-700 pb-5'>{c.by}</h1>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial: `
    In terms of footfall and 
    quality of crowd, most 
    of the visitors were 
    end-users and serious 
    buyers looking for 
    investments. 
    Customers are not just 
    coming in and walking
    off but in fact are 
    serious buyers.
	`,
    by: "Sobha Ltd.",
  },
  {
    testimonial: `
    We are quite happy with the response as we saw significant 
    walk-ins on both days. The best part is that the clients who 
    visited us were good potential clients who were interested in 
    projects across cities such as Mumbai, Pune, and Bangalore etc. 
	`,
    by: "Godrej",
  },
  {
    testimonial: `
    We have seen a very good response. 
    This is the best event in terms of 
    walk-ins as we witnessed -50plus 
    registration for walk-ins at our stall on 
    the first day. They were genuine 
    customers. Out of those -50plus 
    walk-ins, I can confidently say that 15 
    were strong prospects who confirmed 
    to visit the site in coming days. 
	`,
    by: "Puravankara",
  },
  {
    testimonial: `
    The show was 
    organised much 
    better than most of 
    the shows that I've 
    seen.
    
	`,
    by: "L&T",
  },
];

export default Testimonials;
