import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";

const content = [
  {
    title:
      "UAE’s first english newspaper and the most visited newspaper portal and a media publication.",
    image: "27.png",
  },
  {
    title: "Khaleej Times has 75+ Million page views and 6.94 unique visitors",
    image: "28.png",
  },
  {
    title:
      "Khaleej Times has more than 4.5 million followers across Facebook, YouTube, Instagram, LinkedIn, making it one of the strongest online news brand in GCC",
    image: "39.png",
  },
  {
    title:
      "100,000 Printed copies in circulation with the readership base of 407,700.",
    image: "30.png",
  },
];

function KeyNotes() {
  return (
    <>
      <div className="section py-5" style={{ background: "transparent" }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <h1 className="title mb-4 section-title text-center text-danger">
                <b>WHY KHALEEJ TIMES?</b>
              </h1>
              <br />
            </Col>
            {content.map((data, index) => (
              <Col lg={5} xs={6} key={index} className="pb-2 text-center pb-4">
                <img
                  src={require(`assets/icons/${data.image}`)}
                  alt=""
                  width="100%"
                  style={{ maxWidth: "75px" }}
                />
                <h3 className="text-600 mt-3">{data.title}</h3>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
