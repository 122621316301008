import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <h5>
              What makes digital cooperation between the Arab Gulf states
              promising and necessary is not limited to the emotional and
              historical factors, but objective foundations
            </h5>
            <p>
              This title is neither meant to be poetic nor meant to be a slogan.
              It is dictated by necessities and imperatives as public optimism
              is high despite the havoc Covid-19 has wreaked.
            </p>
            <br />
            <br />
            <p>
              In these two weeks, there have been meetings of the GCC in the
              areas of telecommunications, postal services, and e-government.
              Today, the meeting of the GCC e-Government Ministerial Committee
              and the GCC Ministerial Committee for Post &amp;
              Telecommunications will be held. Obviously, these two meetings
              were preceded by preliminary meetings of the Executive Committees
              in both areas. They were also preceded by important preparations
              by the Secretariat General of the GCC, an organisation that was
              and will always be the incubator of the Gulf dream. It follows up
              on the implementation of the agenda and projects of the Arab
              states of the Gulf that are united by a deeply rooted history and
              geography.
              <br />
              Our challenges and hope unite us - the air that we breathe and our
              children's future unite us in light of the difficulties we witness
              today at the global level, as a result of the scientific,
              technological, economic, and social transformations in all fields.
            </p>
            <br />
            <br />
            <p>
              <br />
              Dreams of digital networking that are part of our digital
              programmes bring us together to create the society and knowledge
              economy we aspire to achieve for sustainability and happiness of
              our peoples.
              <br />
              Nevertheless, what makes digital cooperation between the Arab Gulf
              states promising and necessary is not limited to the emotional and
              historical factors, but objective foundations. The results of the
              United Nations e-Government Survey issued a few days ago showed
              that the Arab Gulf states ranked highest at the regional level in
              important indicators such as the E-Government Development Index,
              Online Services Index, E-Participation, and the Telecommunication
              Infrastructure Index, among others.
              <br />
              The Covid-19 pandemic also revealed that the GCC countries are
              among the most prepared to deal with new circumstances and ensure
              the continuity of life in all its activities.
              <br />
              All of the above indicate that the Arab Gulf states are able to
              lead the region towards the desired digital future. This requires
              strengthening collaborations in the areas of digital
              transformation and deployment of advanced technology, technologies
              supported by artificial intelligence, Internet of Things, smart
              cities, blockchain and digital commerce, among others.
              <br />
              Among the GCC states, there are many areas of cooperation in the
              digital field. Areas include the agenda of the relevant
              committees' meetings, as well as other topics that we hope to be
              included soon. We need to keep ourselves abreast of the
              technological developments and spur the pace towards the
              development of a GCC-level digital platform to transfer and
              exchange knowledge. We also need to accelerate the implementation
              of integrated systems, the activation of interconnection projects
              at infrastructure level, starting a joint effort in the field of
              regulation and digital legislation, promoting strong cooperation
              in cybersecurity, and much more.
              <br />
              We are hopeful that the coming years will be more rewarding for
              our Gulf states and for the Arab world in general and will be
              characterised by greater digital cooperation in order to create a
              happy future for all of us. The rapid changes in today's world are
              a constant reminder of the fact that building our future should
              not, and cannot be, an individual act or an isolated effort.
            </p>
            <br />
            <br />
            <p>Hamad Obaid Al Mansoori is the Director General, TRA</p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
