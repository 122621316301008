import React from "react";

import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
// import ScrollAnimation from "react-animate-on-scroll";

const content = [
  {
    title: "Unprecedented Market Potential",
    description:
      "IRES 2023 offers sponsors a unique chance to tap into a significant market of Indians residing in Dubai, keen on investing in Indian and UAE real estate. Showcase properties to an eager audience, fostering connections and generating unparalleled sales.",
    image: "4.png",
  },
  {
    title: "Targeted Audience",
    description:
      "With a B2C focus, sponsors gain direct access to Indian expats in Dubai seeking real estate opportunities in India and UAE. Tailor offerings to meet their specific needs, resulting in higher conversion rates and customer satisfaction.",
    image: "4.png",
  },
  {
    title: "Brand Visibility and Recognition",
    description:
      "Sponsoring the event provides ample exposure and recognition within UAE's Indian community. Leverage extensive marketing eorts for sustained brand visibility and recall.",
    image: "3.png",
  },
  {
    title: "Networking Opportunities",
    description:
      "Gain exclusive access to potential buyers, industry peers, and key stakeholders, fostering valuable collaborations and insights into market trends for a competitive edge.",
    image: "2.png",
  },
  {
    title: "Showcasing Diverse Real Estate Offerings",
    description:
      "Showcase a wide range of properties, catering to various budgets and locations, from luxury apartments to affordable housing and investment options, appealing to every investor's interest and requirement.",
    image: "1.png",
  },
];

function KeyNotes() {
  return (
    <>
      <div
        style={{
          background: "#1d1960",
        }}
      >
        <div
          style={{
            background: `url("${require("assets/images/Asset 2.png")}")`,
            // background: `url(}`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflow: "hidden",
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col className="mx-auto" md={12}>
                <h1 className="title mb-2 section-title text-center text-white">
                  <b>WHY EXHIBIT</b>
                </h1>
                <br />
              </Col>

              {content.map((data, index) => (
                <Col lg={6} xs={12} key={index}>
                  <Card style={{ backgroundColor: "rgb(29,25,96,0.7)" }} className="rounded-0 ">
                    <CardBody>
                      <Container>
                        <Row>
                          <Col lg={12} className="align-self-center text-white border-left">
                            <h2 className="text-700">{data.title}</h2>
                            <h2 className="text-400">{data.description}</h2>
                          </Col>
                        </Row>
                      </Container>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default KeyNotes;
