import React from 'react';
import Navbar from 'components/Navbar.js';
import LandingPageHeader from 'components/Headers/LandingPageHeader.js';
import Footer from 'components/Footer.js';
import TextGrid from 'components/TextGrid.js';

import Testimonials from 'components/Testimonials.js';
import KeyTopics from 'components/KeyTopics.js';
import KeyTopics2 from 'components/KeyTopics2.js';
// import Photos from 'components/Photos.js';


import Stats2 from 'components/Stats2.js';
// import Stats from 'components/Stats.js';

import Sponsor from 'components/Sponsor.js';
import { sponsors } from './content';

function Home() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('profile-page');
    return function cleanup() {
      document.body.classList.remove('profile-page');
    };
  });

  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          {/* <Stats /> */}
          <TextGrid />
          <section id="sponsors" />
          <Sponsor sponsors={sponsors} />
          <Stats2 />
          <KeyTopics />
          <KeyTopics2 />
          <div className="main"></div>
          <Testimonials />
          {/* <Photos /> */}
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
