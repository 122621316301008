import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Slider from "react-slick";

function TextGrid() {
  let pageHeader = React.createRef();
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col className="mx-auto" md={12}>
              <h1 className="title mb-4 section-title text-danger">
                <b>OVERVIEW</b>
              </h1>
              <br />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <h3 className="text-dark text-400">
                India's real estate sector is experiencing a remarkable growth trajectory, projected
                to reach a market size of US$ 1 trillion by 2030, constituting 18-20% of the
                country's GDP. The demand for residential properties is soaring, fueled by increased
                urbanization and rising household incomes.
                <br />
                <br />
                Do you want to seize the opportunity to be a part of India's real estate success
                story?
                <br />
                <br />
                Khaleej Times and Realty Plus is proud to announce the highly anticipated 4th
                edition of the UAE-India Real Estate Show, arriving in Dubai, UAE on the 25th and
                26th of November 2023. The prestigious event shall gather the industry's finest
                players from across UAE and India, providing a comprehensive platform for investors,
                developers, and homebuyers alike to delve into the extraordinary opportunities
                presented by UAE and India's real estate market – Be it Studio Apartments, Holiday
                Homes, High-yield rentals, Luxury homes – at the same time instant discounts, spot
                prizes, attractive payment plans and exclusive deals - All under one roof!
                <br />
                <br />
                Tempted?
                <br />
                <br />
                Join us at the UAE-India Real Estate Show in Dubai and unlock a world of
                possibilities in one of the fastest-growing real estate markets globally.
                <br />
                <br />
                <Button
                  className="px-3 py-2 rounded-0"
                  color="primary"
                  style={{ minWidth: "200px" }}
                  href="/register"
                >
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    REGISTER INTEREST TO ATTEND
                  </p>
                </Button>
              </h3>
            </Col>
            <Col lg={6}>
              <div className="embed-responsive embed-responsive-4by3">
                <iframe
                  src="https://www.youtube.com/embed/Uxi29L336HI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Uxi29L336HI"
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11} className="mt-5">
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width="100%"
                      // style={{ maxWidth: "728px" }}
                      alt=""
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://www.linkedin.com/showcase/futuresecksa/ ", image: "1.jpg" },
  // { link: "https://www.sophos.com/en-us/labs/security-threat-report.aspx?cmp=104846", image: "2.png" },
  // { link: "https://www.youtube.com/watch?v=oFLXD_JDvZs", image: "3.jpg" },
];

export default TextGrid;
