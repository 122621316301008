import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>
              In conversation with Alexey Sidorov, Chief Evangelist Middle East
              & Eastern Europe at Denodo
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <h3>What are your top 3 predictions for AI?</h3>
            <p>
              Gulf region is probably one of the most innovative places in the
              world. People here do always look for the newest and coolest
              things and this mindset is not limited to luxury cars, fashion
              clothes, and mobile phones.
            </p>

            <br />
            <br />
            <p>
              GCC countries are at the top of the list when it comes to the
              adoption of Artificial Intelligence as well. AI is already used by
              government and commercial institutions to improve the life of the
              citizens and increase the happiness of the customers.
            </p>
            <br />
            <br />
            <p>
              In my opinion, top directions for Machine Learning and Artificial
              Intelligence application would be mostly related to Smart City and
              Smart Country programs that make urban living more efficient and
              convenient through everything from smart traffic lights and
              data-driven energy efficiency schemes, to self-driven vehicles and
              enhanced security frameworks.
            </p>
            <br />
            <br />
            <h3>
              While the power of data analytics is beyond doubt, what do you
              think are the crucial challenges in its implementation?
            </h3>
            <p>
              There is a general agreement among the data scientists and
              futurologists are that the main challenge of wide adoption of
              Artificial Intelligence is not technical but ethical.
            </p>
            <br />
            <br />
            <p>
              With the volumes of the data currently available for the Data
              Scientists, the data that we could only dream about some 10-15
              years ago, it is very easy now to cross the red line in very many
              aspects from the personal privacy and up to causing the shifts in
              the whole humankind ecosystem.
            </p>
            <br />
            <br />
            <p>
              Is it ethical to know and analyze the people's movement and their
              physical contacts event in the thought times of the global
              pandemic? If every car tomorrow turns to be self-driven – how do
              we support millions of human taxi-drivers left jobless?
            </p>
            <br />
            <br />
            <p>
              All those and dozens of other questions have to be taken into
              consideration when we are moving forward with AI implementations
              even though there are no technical obstacles on the road.
            </p>
            <br />
            <br />
            <h3>
              How have Data Management technologies in UAE evolved in the
              current landscape?
            </h3>
            <p>
              Same as GCC is one of the most innovative regions in the world,
              UAE is one of the most innovative countries in the Gulf Region.
            </p>
            <br />
            <br />
            <p>
              The adoption of the most advanced Data Management technologies
              including Big Data, Blockchain, Predictive Analytics, and
              Artificial Intelligence in the UAE has always been at the top
              level comparing to any other region.{" "}
            </p>
            <br />
            <br />
            <p>
              However, with the current landscape, influenced dramatically by
              the global pandemic, most of the commercial companies and
              government bodies are rapidly adjusting. While the crisis unfolds,
              organizations should prepare for what is coming next.
            </p>
            <br />
            <br />
            <h3>
              If you could solve any global problem in the world with AI, what
              would it be?{" "}
            </h3>
            <p>
              Climate change is one of the biggest problems in the modern world.
              This is not disputed by any scientific body that the main cause of
              this problem is human activity. Global warming driven by human
              emissions of greenhouse gases results in large-scale shifts in
              weather patterns.
            </p>
            <br />
            <br />
            <p>
              AI is already used to minimize carbon footprint and optimize
              energy consumption. We can see how industry data is used to train
              AI algorithms to make accurate forecasts, helping to balance power
              supply and demand. The great example here is AI algorithms that
              reportedly helped Google to reduce the amount of energy required
              to cool the company’s data centers by 40 percent.
            </p>
            <br />
            <br />
            <p>
              Training models on a huge amount of industry and weather data that
              are currently available for the data scientists we can drive a big
              move in improving energy consumption and help to save our planet.
            </p>
            <br />
            <br />
            <h3>
              What’s the “coolest”/ most “wow-factor" thing you’ve seen so far
              is with the use of AI in the sector you work in?
            </h3>
            <p>
              I’m working with the data for the whole of my life. We can see how
              Data Management companies developing advanced AI algorithms to
              help the other industries to change their business completely
              turning science fiction into reality.
            </p>
            <br />
            <br />
            <p>
              However, and strangely enough, only a handful of IT companies is
              using Artificial Intelligence to enhance its own products for
              customer value creation, productivity improvement, and insight
              discovery.
            </p>
            <br />
            <br />
            <p>
              A very good example of AI and ML application for Data Management
              is the data discovery and profiling algorithms that enable Data
              Governance products to discover, analyze, and understand critical
              patterns in the data, tag the data and relate it to a certain
              domain, trace the data movement inside the organization and
              suggest the most relevant data sets for Data Scientists.
            </p>
            <br />
            <br />
            <h3>
              What are some of the noticeable achievements of your organization
              when it comes to Data Analytics and Artificial Intelligence?
            </h3>
            <p>
              The current data landscape is fragmented. Data lakes, IoT
              architectures, NoSQL, SaaS, etc. coexist with traditional
              relational databases. Denodo not only helps Data Scientists to
              combine all those data and fuel the needs of modern analytics, ML,
              and AI but also enhance the internal AI and ML capabilities across
              its data fabric components including Denodo Data Catalog.
            </p>
            <br />
            <br />
            <h3>
              What can delegates/attendees take away from your session at the
              Artelligence Forum 2020?
            </h3>
            <p>
              Data is the new oil, data is the new gold, data is the new
              currency.
            </p>
            <br />
            <br />
            <p>
              AI and Predictive Analytics is simply impossible without the data.
              The clean, timely, and well-organized data.
            </p>
            <br />
            <br />
            <p>
              Denodo Enterprise Data Fabric provides a modern virtual way to
              connect disparate sources of data, creating a quantum layer of the
              information. Trusted, always real-time data, abstracted from the
              underlying technologies enables Data Scientists to turn the data
              into the new oil. Or the new gold. Or the new currency.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
