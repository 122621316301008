// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/Asset3.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
          // backgroundColor: "rgb(234,208,241)",
          // backgroundImage:
          //   "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
        }}
        className="page-header"
        data-parallax={true}
      >
        {/* <Anime /> */}
        <Container>
          <Row className="align-items-center">
            <Col md={5} xs={12}>
              <div className="d-flex align-items-center">
                <img
                  src={require(`assets/images/logo/navbarlogo.png`)}
                  style={{ maxWidth: "300px" }}
                  width="100%"
                  alt=""
                />
                <div className="d-flex justify-content-start">
                  <div className="flex-2">
                    <img src={require(`assets/images/logo/khaleej.png`)} width="150px" alt="" />
                  </div>
                  <div className="flex-2 border-left">
                    <img src={require(`assets/images/logo/realty.png`)} width="110px" alt="" />
                  </div>
                </div>
              </div>
              <hr />

              <h1
                className="text-700 text-uppercase text-danger m-0"
                style={{
                  fontSize: size ? "2.25rem" : "1rem",
                }}
              >
                THE REGION’S PREMIUM INDIAN REALTY EVENT
              </h1>
              <h1
                className="text-700 text-danger m-0"
                style={{ fontSize: size ? "2.25rem" : "1rem" }}
              >
                25th-26th NOVEMBER 2023
                <br /> DUBAI, UAE
              </h1>
            </Col>

            <Col md={8} xs={12} className="mt-4" style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/register"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        REGISTER{size ? " " : <br />}INTEREST
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
