import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";
import Testimonials from "components/Testimonials.js";

import { sponsors } from "./content";
import { Container, Row, Col } from "reactstrap";

function ThankYou({
  answer,
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU ${answer}`} bgColor='#fff' />
      <div className='section profile-content' style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className='text-center p-5 m-5'>
              <h1>
                {(id === "REPORT" || id === "EBOOK") && (
                  <>
                    Thank you for your interest in India Real Estate Show 2023
                    on 25-26 November 2023. The post-show report has been sent
                    to the email address provided, please check your inbox. In
                    case you do not receive the report,please email us at
                    events@khaleejtimes.com
                  </>
                )}
                {id === "BROCHURE" && (
                  <>
                    Thank you for your interest in India Real Estate Show 2023
                    on 25-26 November 2023. The event brochure has been sent
                    to the email address provided, please check your inbox. In
                    case you do not receive the brochure, please email us at
                    events@khaleejtimes.com
                  </>
                )}
                {id === "REGISTER" && (
                  <>
                    Thank you for registering to attend India Real Estate Show,
                    to be held in-person on 25-26 November 2023. We look
                    forward to welcoming you.
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest in India Real Estate Show 2023.
                    A member of our team will be in touch with you for further
                    details about the event.
                  </>
                )}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Testimonials />
      <Sponsor sponsors={sponsors} />
      <Footer />
    </>
  );
}

export default ThankYou;
