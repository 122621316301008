import React from "react";
import { Button, Container, Row, Col, Input, Form, Alert, Spinner } from "reactstrap";
import { Redirect } from "react-router-dom";

function Register({ type, successMsg }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source");
  const utm_medium = urlParams.get("utm_medium");
  const utm_campaign = urlParams.get("utm_campaign");

  const [details, setDetails] = React.useState({
    type,
    utm_source,
    utm_medium,
    utm_campaign,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    fetch("https://asia-east2-cmoconnext.cloudfunctions.net/api/ires", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        // setSuccess(
        //   successMsg
        //     ? successMsg
        //     : "Thank you for your request. We'll get back to you shortly."
        // );
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  let inputs = content;
  if (type === "BROCHURE") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "REPORT") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "EBOOK") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6].includes(c.id));
  }
  if (type === "SPONSOR") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5, 6, 7].includes(c.id));
  }
  if (type === "MEDIA") {
    inputs = content.filter((c) => [1, 2, 3, 4, 5].includes(c.id));
  }
  if (redirect) {
    return <Redirect to={`/thank-you/${type}`} />;
  }
  return (
    <div
      style={{
        paddingTop: "1rem",
        backgroundColor: "#007dc5",
        // backgroundImage:
        //   "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
        overflow: "hidden",
      }}
    >
      <Container>
        <Row>
          <Col className="mx-auto mb-3 py-5" lg={8}>
            <Container className="my-auto text-main text-uppercase text-white text-400">
              <h1 className="text-center">Registration is Closed.</h1>
              <Form onSubmit={onSubmitForm} className="register-form d-none">
                <Row className="justify-content-center">
                  {inputs.map((i) => (
                    <Col lg={6} className="my-2" key={i.name}>
                      <label>{i.placeholder}*</label>
                      <Input
                        placeholder={i.placeholder}
                        type={i.type}
                        name={i.name}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                  ))}
                  {type === "REGISTER" && (
                    <>
                      {/* <Col lg={12} className='my-2'>
                        <label>Industry *</label>
                        <Input
                          type='select'
                          name='industry'
                          id='industry'
                          onChange={onChangeDetails}
                          required
                        >
                          <option value=''>SELECT</option>
                          {industries.map((industry) => (
                            <option key={industry}>{industry}</option>
                          ))}
                        </Input>
                      </Col> */}
                      <Col lg={12} className="my-2">
                        <label>Which State would you want to buy a property in? *</label>
                        <Input
                          type="select"
                          name="buyProperty"
                          id="buyProperty"
                          onChange={onChangeDetails}
                          required
                        >
                          <option value="">SELECT</option>
                          {states.map((state) => (
                            <option key={state}>{state}</option>
                          ))}
                        </Input>
                      </Col>
                    </>
                  )}
                  <Col lg={12} className="text-center pt-2">
                    <p style={{ textTransform: "none" }}>
                      By clicking download/submit you agree to receive further marketing information
                      from Khaleej Times Events on our series of live and digital events. Please{" "}
                      <a href="/privacy-policy">read our privacy policy here.</a>
                    </p>
                  </Col>
                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    block
                    className="btn text-white text-700 mt-3"
                    color="info"
                    size="lg"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                )}
              </Form>
            </Container>
            <CreateAlert success={success} error={error} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  { id: 1, name: "first_name", type: "text", placeholder: "First Name" },
  { id: 2, name: "last_name", type: "text", placeholder: "Last Name" },
  { id: 4, name: "email", type: "text", placeholder: "Business Email" },
  { id: 5, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 6, name: "company", type: "text", placeholder: "Company" },
  { id: 7, name: "job", type: "text", placeholder: "Job Title" },
  // { id: 8, name: "nationality", type: "text", placeholder: "Nationality" },
];

// const industries = [
//   "Aerospace",
//   "Agriculture",
//   "Automotive",
//   "Banking/Finance",
//   "Construction",
//   "CX & UX",
//   "Emergency Services",
//   "Energy & Utilities",
//   "Environment, Mining & Chemicals",
//   "Government",
//   "Healthcare",
//   "ISP",
//   "Investors/VC",
//   "Manufacturing",
//   "Oil & Gas",
//   "Public Safety",
//   "Publishers",
//   "Retail/e-Commerce",
//   "Sales & Marketing",
//   "Technology",
//   "Other",
// ];

const states = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
