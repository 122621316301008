import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

// import Slider from "react-slick";

function Sponsor({ sponsors }) {
  const rows = [];
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className="text-center text-danger section-title">
                <b>SPONSORS & PARTNERS</b>
              </h1>
            </Col>
          </Row>
          {rows.map((row) => (
            <Row className="my-5">
              {row.map((sponsor) =>
                newsponsor[sponsor].map((s) => (
                  <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                    <h2
                      className="text-dark text-700 text-uppercase text-center pb-3"
                      style={{ margin: 0, padding: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: s.type,
                      }}
                    ></h2>
                    <a href={`${s.link}`}>
                      <Card className="mx-auto" style={{ borderRadius: 0, maxWidth: s.size }}>
                        <img
                          src={require(`assets/sponsors/${s.image}`)}
                          alt={s.name}
                          className="rounded-0"
                          width="100%"
                        />
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
        </Container>
        <Container>
          <Row className="justify-content-center">
            {/* <Col lg={12}>
              <h1 className="text-center text-danger section-title">
                <b>PAST SPONSORS & PARTNERS</b>
              </h1>
            </Col> */}

            {sponsors.map((sponsor, index) => (
              <Col lg={3} xs={6} key={index}>
                <a href={`/sponsors/${sponsor.pageLink}`}>
                  <Container fluid>
                    <Row>
                      <Col
                        lg={12}
                        className="text-center text-dark  mb-2"
                        style={{
                          padding: "1rem 0rem",
                        }}
                      ></Col>
                      <Col lg={12}>
                        <Card style={{ borderRadius: "0", boxShadow: "none" }}>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              paddingBottom: "50%", // 2/3 aspect ratio (3/2 inverted)
                            }}
                          >
                            <img
                              src={require(`assets/sponsors/${sponsor.image}`)}
                              alt={sponsor.name}
                              width="100%"
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center mt-5">
            <Col lg={12}>
              <h1 className="text-center text-danger section-title mb-5">
                <b>PAST EXHIBITORS</b>
              </h1>
            </Col>

            <Col lg={3} xs={6}>
              <Card style={{ borderRadius: "0" }}>
                <ul>
                  {exih1.map((e) => (
                    <li key={e}>
                      <h3 className="text-dark text-400">{e}</h3>
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
            <Col lg={3} xs={6}>
              <Card style={{ borderRadius: "0" }}>
                <ul>
                  {exih2.map((e) => (
                    <li key={e}>
                      <h3 className="text-dark text-400">{e}</h3>
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
            <Col lg={3} xs={6}>
              <Card style={{ borderRadius: "0" }}>
                <ul>
                  {exih3.map((e) => (
                    <li key={e}>
                      <h3 className="text-dark text-400">{e}</h3>
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
            <Col lg={3} xs={6}>
              <Card style={{ borderRadius: "0" }}>
                <ul>
                  {exih4.map((e) => (
                    <li key={e}>
                      <h3 className="text-dark text-400">{e}</h3>
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const newsponsor = groupByType([]);

const exih1 = [
  "Ace",
  "Artha Real Estate Corporation Limited ",
  "Ascent Corporation",
  "Balaji Symphony - New Panvel, Navi Mumbai",
  "Bank Of Baroda",
  "Bramha",
  "Bramhacorp Ltd.",
  "Brigade",
  "Brigade Group",
  "Casagrand",
  "Casagrand Builder Pvt Ltd",
  "Century Financial",
  "Courtyard",
  "G-corp",
  "Gaurs Group",
];
const exih2 = [
  "Godrej Properties",
  "Hiranandani Group",
  "House Of Hiranandani",
  "Indiabulls Home Loans",
  "Indiabulls Real Estate",
  "International Travel Services – Galadari Travel  Group ",
  "Kanakia Group",
  "Kolkata Heights Pvt. Ltd",
  "Kundan Spaces",
  "L & T",
  "L&t Realty ",
  "Land Trades Builders & Developers",
  "Lodha",
  "M3m Gurugram  ( Seedwill )",
];
const exih3 = [
  "Mahindra Lifespace Developers Ltd ",
  "Mantri Developers Pvt Ltd",
  "Nambiar Builders",
  "Nivasti Developers Llp. ",
  "Omaxe Ltd. ",
  "Ozone Group ",
  "Paradise Group Builders & Developers ",
  "Prateek Group",
  "Presithum( F1 Track, India)",
  "Prestige",
  "Property Junction",
  "Puravankara Ltd ",
  "Pushkar Properties",
  "R-tech Group",
  "Radius Developers, Mumbai",
  "Rassaz Developers Llp ",
];

const exih4 = [
  "Raymond Realty",
  "Ruparel Realty",
  "Salma Constructins",
  "Satyam Developers",
  "Sethia Infrastructure",
  "Shapoorji",
  "Sheth Creators Pvt. Ltd.",
  "Shreeraj Developer Llp (Chandak Group)",
  "Sobha",
  "St. Agnelos Vnct Ventures",
  "Sugee",
  "Sunteck",
  "Tata Housing",
  "Total Environment",
  "Tvs Emerald ",
  "Wadhwa",
];

export default Sponsor;
