export const speakers = [];
export const sponsors = [
  { image: "Colliers New Logo.png" },
  { image: "GPL Logo.jpg" },
  { image: "Hero-Realty-logo-Jpg.jpg" },
  { image: "RA Logo-All Sizes-01 (4).png" },
  { image: "Ram Ratna logo.jpg" },
  { image: "regtech.png" },
  { image: "Rise Up Logo - FINAL HQ.png" },
  { image: "Screenshot 2024-01-13 at 11.31.11 AM.png" },
  { image: "Screenshot 2024-01-13 at 11.32.04 AM.png" },
  { image: "Screenshot 2024-01-13 at 11.32.30 AM.png" },
  { image: "Screenshot 2024-01-13 at 11.33.03 AM.png" },
  { image: "Screenshot 2024-01-13 at 11.34.05 AM.png" },
  { image: "Screenshot 2024-01-13 at 11.34.24 AM.png" },
  { image: "Seenrecruit_Logo (White Background) (1).jpg" },
  { image: "SRI BALAJI LOGO.png" },
  { image: "Transparent Seenrecruit logo.png" },
];
export const pastsponsors = [
  {
    name: "India bull Home loans",
    pageLink: "IndiaBull",
    type: "PLATINUM SPONSOR",
    image: "indiabull.jpg",
    text: [],
    link: "https://www.indiabulls.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Hiranandani",
    pageLink: "Hiranandani",
    type: "PLATINUM SPONSOR",
    image: "hiranandani.jpg",
    text: [],
    link: "https://hiranandanioffers.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Omaxe",
    pageLink: "Omaxe",
    type: "PLATINUM SPONSOR",
    image: "omaxe.png",
    text: [],
    link: "https://omaxe.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Godrej properties",
    pageLink: "Godrej properties",
    type: "PLATINUM SPONSOR",
    image: "godrej.jpg",
    text: [],
    link: "https://www.godrejproperties.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Lodha",
    pageLink: "Lodha",
    type: "PLATINUM SPONSOR",
    image: "lodha.jpg",
    text: [],
    link: "https://www.Lodhagroup.in/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Kundan spaces",
    pageLink: "Kundan spaces",
    type: "PLATINUM SPONSOR",
    image: "kundan.jpg",
    text: [],
    link: "https://www.kundans.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Ozone group",
    pageLink: "Ozone group",
    type: "PLATINUM SPONSOR",
    image: "ozone.jpg",
    text: [],
    link: "https://www.ozonegroup.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Bramha Corp",
    pageLink: "Bramha Corp",
    type: "PLATINUM SPONSOR",
    image: "bramha.png",
    text: [],
    link: "https://www.ozonegroup.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Artha",
    pageLink: "Artha",
    type: "PLATINUM SPONSOR",
    image: "artha.png",
    text: [],
    link: "https://www.arthaproperty.com/news/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Kanakia",
    pageLink: "Kanakia",
    type: "PLATINUM SPONSOR",
    image: "kanakia.png",
    text: [],
    link: "https://www.kanakia.com/",
    size: "80%",
    grid: 3,
  },
  {
    name: "Birla Estates",
    pageLink: "Birla Estates",
    type: "GOLD SPONSOR",
    image: "birla.jpg",
    text: [],
    link: "https://www.birlaestates.com/",
    size: "80%",
    grid: 6,
  },
  {
    name: "Puravankara Limited",
    pageLink: "Puravankara Limited",
    type: "GOLD SPONSOR",
    image: "puravankar.png",
    text: [],
    link: "https://www.puravankara.com/",
    size: "80%",
    grid: 6,
  },
  {
    name: "SPR city",
    pageLink: "SPR city",
    type: "EXHIBITOR",
    image: "spr.png",
    text: [],
    link: "https://sprindia.com/#moi-section ",
    size: "80%",
    grid: 4,
  },
  {
    name: "TATA housing",
    pageLink: "TATA housing",
    type: "EXHIBITOR",
    image: "tata.jpg",
    text: [],
    link: "https://www.tatarealty.in/ ",
    size: "80%",
    grid: 4,
  },
  {
    name: "Raymond Realty",
    pageLink: "Raymond",
    type: "EXHIBITOR",
    image: "raymond.png",
    text: [],
    link: "https://raymond-realty.co.in/?gclid=EAIaIQobChMI8vOg1NvK9wIVfY1oCR1gogw7EAAYASAAEgJKd_D_BwE",
    size: "80%",
    grid: 4,
  },
  {
    name: "Amanora",
    pageLink: "Amanora",
    type: "EXHIBITOR",
    image: "amanora.png",
    text: [],
    link: "https://amanora.com/ ",
    size: "80%",
    grid: 4,
  },
  {
    name: "Casagrand",
    pageLink: "Casagrand",
    type: "EXHIBITOR",
    image: "casagrand.jpg",
    text: [],
    link: "https://www.casagrand.co.in/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Bhutani",
    pageLink: "Bhutani",
    type: "EXHIBITOR",
    image: "bhutani.jpg",
    text: [],
    link: "http://www.bhutani-group.in",
    size: "80%",
    grid: 4,
  },
  {
    name: "Hiranandani",
    pageLink: "Hiranandani",
    type: "EXHIBITOR",
    image: "hiranandani2.jpg",
    text: [],
    link: "https://hiranandanioffers.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Shubhashish",
    pageLink: "Shubhashish",
    type: "EXHIBITOR",
    image: "shubha.jpg",
    text: [],
    link: "https://shubhashishhomes.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Realty Expertz",
    pageLink: "Realty Expertz",
    type: "EXHIBITOR",
    image: "realty.jpg",
    text: [],
    link: "http://www.realtyexpertz.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "Signature Global",
    pageLink: "Signature Global",
    type: "EXHIBITOR",
    image: "signature.jpg",
    text: [],
    link: "https://www.signatureglobal.in/",
    size: "80%",
    grid: 4,
  },
  {
    name: "G Square Group",
    pageLink: "G Square Group",
    type: "EXHIBITOR",
    image: "square.jpg",
    text: [],
    link: "https://gsquarehousing.com/",
    size: "80%",
    grid: 4,
  },
  {
    name: "ICAI",
    pageLink: "ICAI",
    type: "SUPPORTING ASSOCIATION",
    image: "icai.png",
    text: [],
    link: "https://www.icai.org/",
    size: "80%",
    grid: 4,
  },
  {
    name: "IBPC",
    pageLink: "IBPC",
    type: "INSTITUTIONAL PARTNER",
    image: "ibpc.jpg",
    text: [],
    link: "https://www.ibpcdubai.com/",
    size: "80%",
    grid: 4,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const news = [
  {
    id: "Reason",
    title: "5 reasons to look forward to the Artelligence Forum",
    image: "11.png",
    link: "5-reasons-to-look-forward-to-the-Artelligence-Forum",
  },
  {
    id: "Step",
    title: "Five Steps To Making the Most of Khaleej Times Artelligence Forum",
    image: "12.png",
    link: "Five-Steps-To-Making-the-Most-of-Khaleej-Times-Artelligence-Forum",
  },
  {
    id: "Alexey",
    title: "#MeetTheAISpeaker: In conversation with Alexey Sidorov",
    image: "10.png",
    link: "MeetTheAISpeaker-In-conversation-with-Alexey-Sidorov",
  },
  {
    id: "Matthias",
    title: "#MeetTheAISpeaker: In conversation with Matthias Schindler",
    image: "9.png",
    link: "MeetTheAISpeaker-In-conversation-with-Matthias-Schindler",
  },
  {
    id: "Hr",
    title: "Feeling unwell and want the day off in Abu Dhabi? Go to HR robot",
    image: "1.png",
    link: "Feeling-unwell-and-want-the-day-off-in-Abu-Dhabi--Go-to-HR-robot",
  },
  {
    id: "Hyperloop",
    title: "Hyperloop systems in UAE, other countries now closer to reality: DP World",
    image: "2.png",
    link: "Hyperloop-systems-in-UAE",
  },
  {
    id: "Advancements",
    title: "The advancements in real world Artificial Intelligence",
    image: "3.png",
    link: "The-advancements-in-real-world-Artificial-Intelligence",
  },
  {
    id: "Bigdata",
    title: "AI and Big Tech should not have control over health",
    image: "4.PNG",
    link: "AI-and-Big-Tech-should-not-have-control-over-health",
  },
  {
    id: "Coop",
    title: "The GCC: A bright future",
    image: "5.PNG",
    link: "The-GCC-A-bright-future",
  },
  {
    id: "AIbots",
    title: "AI bots to boost care at Saudi German Hospital",
    image: "6.PNG",
    link: "AI-bots-to-boost-care-at-Saudi-German-Hospital",
  },
  {
    id: "Transform",
    title: "Digital transformation to drive recovery of GCC retail sector post-Covid-19",
    image: "7.PNG",
    link: "Digital-transformation",
  },
  {
    id: "Cyber",
    title: "Cyber security vital to Dubai's sustained progress: Sheikh Hamdan",
    image: "8.PNG",
    link: "Cyber-security-Sheikh-Hamdan",
  },
];
