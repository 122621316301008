import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import WhySponsor from "components/WhySponsor";
import WhoAttend from "components/WhoAttend";
import Register from "components/Register";

import Footer from "components/Footer.js";
import Testimonials from "components/Testimonials.js";
import { Redirect } from "react-router";
import Sponsor from "components/Sponsor.js";
import { sponsors, sponsorsPage } from "./content";
import SponsorPage from "components/SponsorPage";
// import Testimonials from "components/Testimonials.js";

function Overview({ match }) {
  const { type } = match.params;

  if (
    !type ||
    ![
      "sponsors-partners",
      "why-sponsor",
      "who-attends",
      "download-brochure",
      "download-report",
      "sponsorship-enquiry",
      "download-ebook-report",
    ].includes(type)
  ) {
    return <Redirect to="/sponsor/why-sponsor" />;
  }
  let title = "";
  if (type === "why-sponsor") {
    title = "WHY SPONSOR";
  }
  if (type === "who-attends") {
    title = "WHO ATTENDS";
  }
  if (type === "download-brochure") {
    title = "DOWNLOAD BROCHURE";
  }
  if (type === "download-report") {
    title = "DOWNLOAD REPORT";
  }
  if (type === "download-ebook-report") {
    title = "DOWNLOAD REPORT";
  }
  if (type === "sponsorship-enquiry") {
    title = "Sponsorship Enquiry";
  }
  if (type === "sponsors-partners") {
    title = "Sponsors & Partners";
  }

  return (
    <>
      <Navbar />
      <ProfilePageHeader title={title} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        {type === "sponsors-partners" && (
          <SponsorPage sponsors={sponsorsPage} />
        )}
        {type === "why-sponsor" && <WhySponsor />}
        {type === "who-attends" && <WhoAttend />}
        {type === "download-brochure" && (
          <Register
            type="BROCHURE"
            title=""
            successMsg={`Thank you for your request. An email with the event brochure has been sent to the email address provided. For more information, you can contact us on +971 56 6292801.`}
          />
        )}
        {(type === "download-report" || type === "download-ebook-report") && (
          <Register
            type="EBOOK"
            title=""
            successMsg={`Thank you for sharing your details with us. An email with the report has been sent to the provided email address. Incase you have not received this, contact us on marketing@remoteworkforcesummit.com`}
          />
        )}
        {type === "sponsorship-enquiry" && <Register type="SPONSOR" title="" />}
      </div>
      <Testimonials />
      {type !== "sponsors-partners" && <Sponsor sponsors={sponsors} />}
      <Footer />
    </>
  );
}

export default Overview;
