import React from 'react';

import { Container, Row, Col } from 'reactstrap';
// import ScrollAnimation from 'react-animate-on-scroll';

const content = [
  { image: '1.jpeg' },
  { image: '2.jpeg' },
  { image: '3.jpeg' },
  { image: '4.jpeg' },
  { image: '5.jpeg' },
  { image: '6.jpeg' },
  { image: '7.jpeg' },
  { image: '8.jpeg' },
  { image: '9.jpeg' },
  { image: '10.jpeg' },
  { image: '12.jpeg' },
  { image: '13.jpeg' },
  { image: '14.jpeg' },
  { image: '15.jpeg' },
  { image: '16.jpeg' },
];

function KeyNotes() {
  return (
    <>
      <div className="section py-5" style={{ background: 'transparent' }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <h1 className="title mb-4 section-title text-center text-danger">
                <b>2022 Edition Highlights</b>
              </h1>
              <br />
            </Col>
            {content.map((data, index) => (
              <Col lg={4} xs={6} key={index} className="px-0 text-center">
                <img src={require(`assets/photos/${data.image}`)} alt="" width="100%" />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
