import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function TextGrid() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section border-top border-bottom"
        style={{
          background: "#fbfbfb",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <img
                src={require(`assets/images/attend.jpg`)}
                width="100%"
                alt=""
                // style={{ border: "15px solid #34368b" }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="text-dark">
              <h3 className="text-400">
                <br />
                <br />
                <b> FutureSec Summit</b> was born as a response to the need of
                industries and sectors that were vulnerable to cyber threats.
                Attended by over 150 technology, security and business leaders,
                FutureSec 2019 marked Saudi’s first information security summit
                for the banking industry. The summit has now developed into a
                well architected gathering of notable information security and
                IT leaders that oers high-level educational program, networking
                and business opportunities in the Kingdom of Saudi Arabia.
                <br />
                <br />
              </h3>
            </Col>

            <Col md={12} className="mx-auto mt-5 align-self-center text-center">
              <Button
                href="/sponsor/download-brochure"
                className="btn"
                color="primary"
                size="md"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  REQUEST AGENDA
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}

export default TextGrid;
